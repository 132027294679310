<template>
  <action-dropdown-item
    link-class="d-flex align-items-center"
    @click.prevent="suspendUser"
  >
    <feather-icon
      size="16"
      icon="AlertTriangleIcon"
      class="mr-50"
    />
    <span>{{ buttonText }}</span>
  </action-dropdown-item>
</template>
<script>
  
import ActionDropdownItem from "@/views/components/common/ActionDropdownItem.vue";
import {
  BDropdownItem
} from "bootstrap-vue";
  
import { makeSuccessToast, makeErrorToast } from "@/libs/utils";
import usersService  from "@/services/usersService";
import { userStatus } from "@models";

  
export default {
  name: 'SuspendUser',
  components: {
    BDropdownItem,
    ActionDropdownItem
  },
  props:{
    user: {
      type: Object,
      default: () => {},
      required: true
    },
    userId: {
      type:Number,
      required: true
    },
    isClientUser: {
      type:Boolean
    }
  },
  computed: {
    isUserSuspended() {
      return this.user.user_roles[0].status_id ===  userStatus.SUSPENDED;
    },
    buttonText () {
      return !this.isUserSuspended ? "Suspend" : "Unsuspend";
    },
    modalTitle () {
      return !this.isUserSuspended ? 'Suspend Participant' : 'Unsuspend Participant';
    },
    modalMessage () {
      return !this.isUserSuspended ? 
        'Are you sure you want to suspend this user? They will not have access to this program whilst they are suspended. Please note that this is only suspending the participant from this program. If they need to be suspended from all programs, please contact Art of Mentoring.' :
        'Are you sure you want to unsuspend this user? They will regain their access to this program. If they were in a published match then it will resume.';
    },
    updatedStatus () {
      return this.isUserSuspended ? userStatus.ACTIVE : userStatus.SUSPENDED; 
    },
    successMsg () {
      return !this.isUserSuspended ?  'Participant suspended.': 'Participant Unsuspended.';
    }
  },
  methods: {
    async suspendUser() {
      this.$bvModal
        .msgBoxConfirm(`${this.modalMessage}`, {
          title: `${this.modalTitle}`,
          size: 'sm',
          okVariant: 'primary',
          okTitle: `${this.buttonText}`,
          cancelTitle: 'Cancel',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if(value) {
            try {
              let result = null;
              if (this.isClientUser) {
                const clientId = this.$route.params.id || this.$route.params.clientId;
                result = await usersService.setClientParticipantStatus(clientId, this.user.id, {status_id: this.updatedStatus});
              } else {
                const data = {
                  status_id: this.updatedStatus,
                  users: [ {id: this.user.id }]
                };
                const programId = this.$route.params.id;
                result = await usersService.setManyParticipantStatus(programId, data);
              }
              this.$emit('updated');
              if (result) {
                this.$toast(makeSuccessToast(`${this.successMsg}`));
              }
            } catch (e) {
              console.log(e);
              const { data } = e.response;
              this.$toast(makeErrorToast(data.message));
              this.$log.error(e);
            }
          }
        });
    }
  }
};
</script>
   